import React, {useEffect, useRef, useState} from 'react';
import {ClubLogo} from '../ClubLogo';

export async function fetchScoreCardGeneral(match_id, teams) {
    try {
        const res = await fetch(`${process.env.API_URL}/scorecard/${match_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.API_KEY,
            },
        });
        if (res.status === 200) {
            const data = await res.json();
            if (data.length > 0) {
                let inns = [];
                inns[1] = data[0].score.inns1;
                inns[2] = data[0].score.inns2;
                inns[3] = data[0].score.inns3;
                inns[4] = data[0].score.inns4;
                let homeScore = '';
                let awayScore = '';
                let cinns = data[0].score.inns_now;
                if (teams[0] === data[0].score.bat_now) {
                    homeScore = inns[cinns];
                    if (cinns > 1) {
                        awayScore = inns[cinns - 1];
                    }
                } else {
                    awayScore = inns[cinns];
                    if (cinns > 1) {
                        homeScore = inns[cinns - 1];
                    }
                }
                return {
                    homescore: homeScore,
                    awayscore: awayScore,
                    batnow: data[0].score.bat_now,
                };
            }
        } else {
            console.error('Failed to fetch scorecard:', res.status);
            return {homescore: "", awayscore: "", batnow: ""}
        }
    } catch (error) {
        console.error('Unknown error:', error);
        return {homescore: "", awayscore: "", batnow: ""}
    }
}

export const LiveAndUpcomingMatches = () => {
    const [matches, setMatches] = useState([]);
    const [liveScore, setLiveScore] = useState({});
    const intervalsRef = useRef([]);

    async function fetchScoreCard(match_id, teams) {
        const scoreCardFromServer = await fetchScoreCardGeneral(match_id, teams);
        setLiveScore((prevState) => ({
            ...prevState,
            [match_id]: scoreCardFromServer,
        }));
    }

    async function fetchData() {
        const res = await fetch(process.env.API_URL + '/fixturelist?match_id_contains=LIVE', {
            method: 'GET', headers: {
                'Content-Type': 'application/json', 'x-api-key': process.env.API_KEY
            },
        });
        const data = await res.json();
        const mData = data.matches;
        let matchBuild = [];
        mData.forEach((match) => {
            match.competition = match.competition.replace(
                'Indian Premier League',
                'IPL 2025'
            );

            let matchStart = new Date(match.start);
            let today = new Date();
            match.localTime = matchStart.toLocaleString('en-US', {
                weekday: 'short',
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
            });
            let diff = matchStart - today;

            if (diff < 0 && match.localTime !== 'IN-PLAY') {
                match.diff = 'Delayed';
            } else {
                let mm = Math.floor(diff / 1000 / 60);
                let hh = Math.floor(diff / 1000 / 60 / 60);
                let days = Math.floor(hh / 24);
                if (days > 0) {
                    let remainingHours = hh % 24;
                    match.diff = days + 'D ' + remainingHours + 'H';
                } else if (hh > 0) {
                    match.diff = hh + 'H';
                } else {
                    match.diff = mm + 'M';
                }
            }
            matchStart.setHours(0, 0, 0, 0);
            today.setHours(0, 0, 0, 0);
            let name = match.name.split(',');
            let teams = name[0].split(' v ');
            if (
                match.status.substring(0, 17) === 'Match in Progress' ||
                match.status.substring(0, 13) === 'Break in Play' ||
                match.status.substring(0, 16) === 'Innings Complete' ||
                match.status.substring(0, 8) === 'Play to '
            ) {
                match.localTime = 'IN-PLAY';
                fetchScoreCard(match.id, teams);
                const intervalId = setInterval(() => {
                    fetchScoreCard(match.id, match.teams);
                }, 30000); // 30 seconds interval
                intervalsRef.current.push(intervalId);
            } else if (
                match.status.substring(0, 14) === 'Match Complete' ||
                match.status.substring(0, 12) === 'Event Closed'
            ) {
                match.localTime = 'Match Complete';
                fetchScoreCard(match.id, teams);
            }
            match.wal = match.watchalong !== null;
            if (matchStart >= today || match.localTime === 'IN-PLAY') {
                if (match.end === '') {
                    match.teams = teams;
                    matchBuild.push(match);
                }
            }
        });
        matchBuild.sort((a, b) => new Date(a.start) - new Date(b.start));
        setMatches(matchBuild);
    }

    useEffect(() => {
        fetchData();
        return () => {
            intervalsRef.current.forEach(clearInterval);
        };
    }, []);

    const handleMatchClick = (matchId, localTime) => {
        if (localTime === 'IN-PLAY' || localTime === 'FINISHED') {
            window.location = '/matchdetail?id=' + matchId;
        }
    };

    return (
        <div className='w-[100%] overflow-x-auto'>
            <div className='flex gap-4'>
                {matches.map((match, index) => {
                    return (
                        <div
                            key={match.id}
                            className={`MatchCard w-[260px] ${
                                match.localTime === 'IN-PLAY' || match.localTime === 'FINISHED'
                                    ? 'cursor-pointer'
                                    : ''
                            }`}
                            onClick={() => handleMatchClick(match.id, match.localTime)}
                        >
                            <div className='header h-[40px]' style={{backgroundColor: '#3B1A63'}}>
                                <div className='title truncate !text-[12px]'>
                                    {match.competition}
                                </div>
                                <div
                                    className={`schedule mr-[10px] ${
                                        match.localTime === 'IN-PLAY' ? 'text-red flex' : ''
                                    }`}
                                >
                                    {match.localTime}
                                    {match.localTime === 'IN-PLAY' ? (
                                        <div className='live-indicator'>
                                            <div className='inner'></div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className='relative w-[260px] h-[120px] mt-[1px]'>
                                <div className='absolute w-[260px] h-[120px] top-0 left-0 bg-white'
                                     style={{backgroundColor: liveScore[match.id]?.batnow && liveScore[match.id]?.batnow !== match.teams[0] ? '#ddd4e9' : ''}}/>
                                <div className='absolute top-[10px] left-[10px]'>
                                    <ClubLogo teamName={match.teams[0]}/>
                                </div>
                                <div
                                    className='absolute top-[80px] left-[10px] font-chakra bold text-[12px] text-main truncate'>
                                    {match.teams[0]}
                                </div>
                                <div
                                    className='absolute top-[10px] right-[10px] !text-right font-chakra bold text-main text-[26px]'>
                                    {liveScore[match.id]?.homescore || '-'}
                                </div>
                                {liveScore[match.id]?.batnow === match.teams[0] ? (
                                    <div className='absolute w-[3px] h-[120px] top-0 right-[0px] bg-red'/>
                                ) : null}
                            </div>
                            <div className='relative w-[260px] h-[120px] mt-[1px]'>
                                <div className='absolute w-[260px] h-[120px] top-0 left-0 bg-white'
                                     style={{backgroundColor: liveScore[match.id]?.batnow && liveScore[match.id]?.batnow !== match.teams[1] ? '#ddd4e9' : ''}}/>
                                <div className='absolute top-[10px] left-[10px]'>
                                    <ClubLogo teamName={match.teams[1]}/>
                                </div>
                                <div
                                    className='absolute top-[80px] left-[10px] font-chakra bold text-[12px] text-main truncate'>
                                    {match.teams[1]}
                                </div>
                                <div
                                    className='absolute top-[10px] right-[10px] !text-right font-chakra bold text-main text-[26px]'>
                                    {liveScore[match.id]?.awayscore || '-'}
                                </div>
                                {liveScore[match.id]?.batnow === match.teams[1] ? (
                                    <div className='absolute w-[3px] h-[120px] top-0 right-[0px] bg-red'/>
                                ) : null}
                            </div>
                            <div className='footer justify-between' style={{backgroundColor: '#3B1A63'}}>
                                {match.localTime === 'IN-PLAY' || match.localTime === 'FINISHED' ? (
                                    <></>
                                ) : (
                                    <div className='flex items-center w-full ml-[8px]'>
                                        <div className='text-[12px] block'>
                                            {match.localTime === 'IN-PLAY' ? (
                                                <>
                                                    {match.watchalong !== null ? (
                                                        <span>IN-PLAY</span>
                                                    ) : (
                                                        <span>
                              <img
                                  className='pr-[10px]'
                                  alt='Vector'
                                  src='/img/camera.svg'
                              />
                              WATCH ALONG LIVE
                            </span>
                                                    )}
                                                </>
                                            ) : match.localTime === 'FINISHED' ? (
                                                <span>FINISHED</span>
                                            ) : (
                                                <>
                                                    <div className='font-chakra text-[12px] w-full'>
                                                        {match.diff === 'Delayed' ? (
                                                            'DELAYED'
                                                        ) : (
                                                            <>STARTS IN {match.diff}</>
                                                        )}
                                                    </div>
                                                    <div className='w-full flex flex-row justify-between items-center'>
                                                        <div
                                                            className='flex flex-row justify-center items-center gap-[4px] font-chakra text-[12px] text-[#32f78c]'>
                                                            {match.watchalong !== null ? (
                                                                <>
                                                                    <svg
                                                                        xmlns='http://www.w3.org/2000/svg'
                                                                        width='1em'
                                                                        height='1em'
                                                                        viewBox='0 0 24 24'
                                                                    >
                                                                        <path
                                                                            fill='currentColor'
                                                                            d='M17 10.5V7a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-3.5l4 4v-11z'
                                                                        />
                                                                    </svg>
                                                                    WATCH-ALONG SCHEDULED
                                                                </>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        {(match.localTime === 'IN-PLAY' ||
                                            match.localTime === 'FINISHED') && (
                                            <div className='prompt text-[#1b062f]'>
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='1.78em'
                                                    height='1em'
                                                    viewBox='0 0 16 9'
                                                >
                                                    <path
                                                        fill='currentColor'
                                                        d='M12.5 5h-9c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h9c.28 0 .5.22.5.5s-.22.5-.5.5'
                                                    />
                                                    <path
                                                        fill='currentColor'
                                                        d='M10 8.5a.47.47 0 0 1-.35-.15c-.2-.2-.2-.51 0-.71l3.15-3.15l-3.15-3.15c-.2-.2-.2-.51 0-.71s.51-.2.71 0l3.5 3.5c.2.2.2.51 0 .71l-3.5 3.5c-.1.1-.23.15-.35.15Z'
                                                    />
                                                </svg>
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className={`action truncate`}>
                                    <div className='justify-self-end px-[13px] py-[11px]'>
                                        <span>MATCH</span>
                                    </div>
                                    {(match.localTime === 'IN-PLAY' || match.localTime === 'Match Complete') && (

                                        <div className='prompt text-[#1b062f]'>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='1.78em'
                                                height='1em'
                                                viewBox='0 0 16 9'
                                            >
                                                <path
                                                    fill='currentColor'
                                                    d='M12.5 5h-9c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h9c.28 0 .5.22.5.5s-.22.5-.5.5'
                                                />
                                                <path
                                                    fill='currentColor'
                                                    d='M10 8.5a.47.47 0 0 1-.35-.15c-.2-.2-.2-.51 0-.71l3.15-3.15l-3.15-3.15c-.2-.2-.2-.51 0-.71s.51-.2.71 0l3.5 3.5c.2.2.2.51 0 .71l-3.5 3.5c-.1.1-.23.15-.35.15Z'
                                                />
                                            </svg>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
export default LiveAndUpcomingMatches;
